<template>
    <section class="edit-category">
        
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-title">
                        <div class="row">
                            <div class="col-12">
                                <h3 class="page-title float-left">Edit Footer</h3>
                                <router-link class="float-right" to="/website-management/footer">
                                    <i class="mdi mdi-window-close font-size-18 text-dark"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <form class="pt-2" @submit.prevent="editFooterPage">
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label for="name">Logo</label>
                                        <b-button v-b-modal.modal-xl class="choose-image">Choose
                                            Image</b-button>

                                        <chooseImage @imageId="setImageId"></chooseImage>
                                        <vue-dropzone ref="myVueDropzone" id="thumbVideo " :options="dropzoneOptions"
                                            :useCustomSlot="true" v-on:vdropzone-success="success"
                                            @vdropzone-sending="updateAvater">
                                            <div class="dropzone-custom-content">
                                                <div class="dropzone-custom-content">
                                                    <div v-if="bannerImg">
                                                        <div class="image-size-dropzone">
                                                            <img :src="`${imageApiURL}/${bannerImg.thumb_s}`"
                                                                class="show-img img-fluid" />
                                                        </div>
                                                        <div class="subtitle pt-1 pb-0">
                                                            <span> Change </span>
                                                            <span style="color: #eb2027"> Browse</span>
                                                        </div>
                                                    </div>
                                                    <div v-if="!bannerImg" class="pt-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43"
                                                            viewBox="0 0 24 24" fill="transparent" stroke="currentColor"
                                                            stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="feather feather-upload"
                                                            color="">
                                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4">
                                                            </path>
                                                            <polyline points="17 8 12 3 7 8"></polyline>
                                                            <line x1="12" y1="3" x2="12" y2="15"></line>
                                                        </svg>
                                                        <div class="subtitle pt-1 pb-5">
                                                            <span> Drop File or </span>
                                                            <span style="color: #eb2027"> Browse</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </vue-dropzone>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="form-group">
                                        <label for="content">Content</label>
                                        <vue-editor v-model="editFooter.content" id="content" name="content"
                                            use-custom-image-handler @image-added="handleImageAdded" :class="{
                                                'is-invalid': submitted && $v.editFooter.content.$error,
                                            }"></vue-editor>
                                        <div v-if="submitted && !$v.editFooter.content.required"
                                            class="invalid-feedback">
                                            Content is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4 class="card-title mb-3 px-0 pb-2">Information</h4>
                            <div class="row" v-for="(info, index) in information" :key="index">
    <div class="col-md-5">
        <div class="form-group">
            <label for="name">Title</label>
            <input type="text" v-model="info.title" class="form-control" />
        </div>
    </div>
    <div class="col-md-5">
        <div class="form-group">
            <label for="name">Url</label>
            <input type="text" v-model="info.url" class="form-control" />
        </div>
    </div>
    <div class="col-md-2">
        <div class="form-group float-left mb-0 pt-3">
            <a @click="removeInformation(index)" v-if="information.length > 1"
                class="btn btn btn-add-sub btn-outline-dark" v-b-tooltip.hover title="Delete">
                <i class="mdi mdi-delete"></i>
            </a>
            <a class="btn btn btn-add-sub btn-outline-primary" @click="addInformation" v-b-tooltip.hover title="Add">
                <i class="mdi mdi-plus"></i>
            </a>
        </div>
    </div>
</div>



                            <h4 class="card-title mb-3 px-0 pb-2">Customer Service</h4>
                            <div class="row" v-for="(customer, index) in customer_service" :key="index">
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label for="name">Title</label>
                                        <input type="text" v-model="customer.title" class="form-control" />
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label for="name">Url</label>
                                        <input type="text" v-model="customer.url" class="form-control" />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group float-left mb-0 pt-3">
                                        <a @click="removecustomer_service(index)" v-if="customer_service.length > 1"
                                            class="btn btn btn-add-sub btn-outline-dark" v-b-tooltip.hover
                                            title="Delete">
                                            <i class="mdi mdi-delete"></i>
                                        </a>
                                        <a class="btn btn btn-add-sub btn-outline-primary" @click="addcustomer_service"
                                            v-b-tooltip.hover title="Add ">
                                            <i class="mdi mdi-plus"> </i>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <h4 class="card-title mb-3 px-0 pb-2">My Account</h4>
                            <div class="row" v-for="(account, index) in my_account" :key="index">
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label for="name">Title</label>
                                        <input type="text" v-model="account.title" class="form-control" />
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label for="name">Url</label>
                                        <input type="text" v-model="account.url" class="form-control" />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group float-left mb-0 pt-3">
                                        <a @click="removemy_account(index)" v-if="my_account.length > 1"
                                            class="btn btn btn-add-sub btn-outline-dark" v-b-tooltip.hover
                                            title="Delete">
                                            <i class="mdi mdi-delete"></i>
                                        </a>
                                        <a class="btn btn btn-add-sub btn-outline-primary" @click="addmy_account"
                                            v-b-tooltip.hover title="Add ">
                                            <i class="mdi mdi-plus"> </i>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <h4 class="card-title mb-3 px-0 pb-2">Social Media</h4>
                            <div class="row" v-for="(social, index) in social_media" :key="index">
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label for="name">Icon</label>
                                        <select class="custom-select form-control" v-model="social.title">
                                            <option selected> </option>
                                            <option value="icon-facebook-f">Facebook</option>
                                            <option value="icon-twitter"> Twitter</option>
                                            <option value="icon-instagram">Instagram</option>
                                            <option value="icon-youtube"> Youtube</option>
                                            <option value="icon-pinterest"> Pinterest</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="form-group">
                                        <label for="name">Url</label>
                                        <input type="text" v-model="social.url" class="form-control" />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group float-left mb-0 pt-3">
                                        <a @click="removesocial_media(index)" v-if="social_media.length > 1"
                                            class="btn btn btn-add-sub btn-outline-dark" v-b-tooltip.hover
                                            title="Delete">
                                            <i class="mdi mdi-delete"></i>
                                        </a>
                                        <a class="btn btn btn-add-sub btn-outline-primary" @click="addsocial_media"
                                            v-b-tooltip.hover title="Add ">
                                            <i class="mdi mdi-plus"> </i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group float-right mb-0 pt-3">

                                        <button class="btn btn btn-add btn-primary">Update</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import { setingsApi } from "../../../api";
import { required } from "vuelidate/lib/validators";
const baseURL = process.env.VUE_APP_BASE_URL;
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
const imageApiURL = process.env.VUE_APP_IMAGE_URL;
import vue2Dropzone from "vue2-dropzone";
import {
    VueEditor
} from "vue2-editor";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

import chooseImage from "../../../components/chooseImage";
export default {
    name: "add-invoice",
    components: {
        
        VueEditor,
        vueDropzone: vue2Dropzone, chooseImage
    },
    data() {
        return {
            bannerImg: "", imageApiURL,
            options: [],
            dropzoneOptions: {
                url: `${mediaApiURL}/file/upload`,
            },
            editFooter: {
                content: "",
                images: ""
            },
            
            submitted: false,
            information: [{
                title: '',
                url: ""
            }],
            customer_service: [{
                title: '',
                url: ''
            }],
            my_account: [{
                title: '',
                url: ''
            }],
            social_media: [{
                title: '',
                url: ''
            }],
        };
    },
    validations: {
        editFooter: {
            content: { required },
        },
    },
    mounted() { this.getFooterById()},
    methods: {
        async getFooterById() {
    
    try {
        const { data } = await setingsApi.getFooterById(this.$route.params.id);
        const footerData = data.data.data;

        if (footerData) {
            const footer = footerData[0]; // Assuming it's an array and you want the first object
            this.editFooter.content = footer.content || "";
            this.information = footer.information || [{ title: "", url: "" }];
            this.customer_service = footer.customer_service || [{ title: "", url: "" }];
            this.my_account = footer.my_account || [{ title: "", url: "" }];
            this.social_media = footer.social_media || [{ title: "", url: "" }];
            this.bannerImg = footer.images?.[0] || ""; // Assuming `images` is an array
        }
    } catch (error) {
        console.error("Error fetching footer data:", error);
    } finally {
        
    }
},
        async editFooterPage() {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            
            this.editFooter = {
                content: this.editFooter.content
            };

            if ((this.information[0].title !== "") && (this.information[0].url !== "")){
                this.editFooter.information = this.information;
            }

            if ((this.customer_service[0].title !== "") && (this.customer_service[0].url !== "")){
                this.editFooter.customer_service = this.customer_service;
            }

            if ((this.my_account[0].title !== "") && (this.my_account[0].url !== "")){
                this.editFooter.my_account = this.my_account;
            }

            if ((this.social_media[0].title !== "") && (this.social_media[0].url !== "")){
                this.editFooter.social_media = this.social_media;
            }

            if (this.bannerImg?.id) {
                this.editFooter.images = [this.bannerImg.id];
            }
            console.log(this.editFooter);

            const data = await setingsApi.updateFooter(this.$route.params.id, this.editFooter,
            );
            
            this.$router.push("/website-management/footer");
            if (data.status == 200) {
                this.$swal({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    type: "success",
                    title: " ",
                    text: data.messages,
                });
            } else {
                this.$swal({
                    type: "error",
                    title: "Oops...",
                    text: data.messages,
                });
            }
        },
        setImageId(selectedId) {
            
            axios.get(`${mediaApi}/files/${selectedId}`).then((response) => {
                this.bannerImg = response.data.data[0];
                
            });
        },
        updateAvater(file, xhr, formData) {
            let folder = "products";
            xhr.setRequestHeader("Header", "");
            formData.append("folder", folder);
        },
        success(file, response) {
            this.addBanner.images = response.data.id;
            this.bannerImg = ""
            if (response.status == "success") {
                this.$swal({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    type: "success",
                    title: " ",
                    text: "Updated successfully",
                });
            } else {
                this.$swal({
                    type: "error",
                    title: "Oops...",
                    text: "The given data was invalid",
                });
            }
        },
        handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
            var formData = new FormData();
            let pages = "pages";
            formData.append("file", file);
            formData.append("folder", pages);
            axios({
                url: `${baseURL}/api/media/file/upload`,
                method: "POST",
                data: formData,
            })
                .then((result) => {
                    let url = result.data.data.path; // Get url from response
                    url = `${baseURL}/public/${url}`;
                    Editor.insertEmbed(cursorLocation, "image", url);

                    resetUploader();
                })
                .catch((err) => {
                    alert("image not uploaded " + err);
                });
        },
        addInformation() {
            this.information.push({

                title: '',
                url: ''
            });
        },
        removeInformation(id) {
            if (this.information.length > 1) {
                this.information = this.information.filter(info => info.id !== id);
            } else {
                alert('At least one Information is required.');
            }
        }
        ,

        addcustomer_service() {
            this.customer_service.push({
                title: '',
                url: ''
            });
        },
        removecustomer_service(index) {
            if (this.customer_service.length > 1) {
                this.customer_service.splice(index, 1);
            } else {
                alert('At least one customer Service is required.');
            }
        },

        addmy_account() {
            this.my_account.push({
                title: '',
                url: ''
            });
        },
        removemy_account(index) {
            if (this.my_account.length > 1) {
                this.my_account.splice(index, 1);
            } else {
                alert('At least one my Account Service is required.');
            }
        },

        addsocial_media() {
            this.social_media.push({
                title: '',
                url: ''
            });
        },
        removesocial_media(index) {
            if (this.social_media.length > 1) {
                this.social_media.splice(index, 1);
            } else {
                alert('At least one Social Media Service is required.');
            }
        }
    },
};
</script>

<style scoped>
.dropzone {
    padding: 40px 20px !important;
    min-height: 207px !important;
}

.btn-add-sub {
    padding: 10px !important;
    margin: 7px 0px 0px 7px;
    border-radius: 2px;

}
</style>
